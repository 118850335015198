import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Icons from "@mui/icons-material";
import * as Mui from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import Person4Icon from "@mui/icons-material/Person4";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { themes } from "services/constants";
import { CustomButton } from "components/CustomComponents/CustomButton";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useFetch } from "services/hooks/useFetch";
import sessionHandling from "services/utils/notificationUtils";
export const AddDepartment = ({ setOpen, setDepartmentApi }) => {
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [shiftCategoryName, setShiftCategoryName] = useState("");
  const [departmentLeadName, setDepartmentLeadName] = useState("");
  const [departmentProxyName, setDepartmentProxyName] = useState("");
  const [departmentProxyList, setDepartmentProxyList] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);

  const {
    setValue,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { result: departmentLeadList } = useFetch(
    `${domain}departmentleadfilter/`
  );
  const { result: shiftCategoryList } = useFetch(`${domain}shift_category/`);

  const onSubmit = async (data) => {
    setButtonLoader(true);
    setTimeout(() => null, 1000);
    if (data) {
      try {
        const response = await fetch(`${domain}create_department/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
          body: JSON.stringify({
            department_lead_id: data.departmentLeadId,
            shift_category: shiftCategoryName,
            departmentname: data.department.trim(),
            proxy_id: data.departmentProxy,
            approved_by: userDetails.username,
            selectedMembers: null,
          }),
        });
        const res = await response.json();
        if (response.ok) {
          reset();
          setDepartmentApi(true);
          showToast("success", "Department created successfully");
          setOpen(false);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setButtonLoader(false);
      }
    }
  };
  const checkSpecialChar = (e) => {
    if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
      e.preventDefault();
    }
  };

  const getDepartmentProxyList = (leadName) => {
    if (leadName !== "") {
      setDepartmentProxyList(departmentLeadList);
    } else {
      setDepartmentProxyList([]);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid xs={12} sm={12} md={3} lg={2.5}>
            <Mui.Stack direction="row">
              <BackArrow actionFunction={() => setOpen(false)} />
              &nbsp;
              <Mui.Typography
                sx={{
                  paddingLeft: 2,
                  fontSize: 22,
                  fontWeight: "bold",
                  paddingTop: 1,
                }}
              >
                Add Department
              </Mui.Typography>
            </Mui.Stack>
          </Mui.Grid>

          <Mui.Grid xs={12} sm={12} md={6} lg={7.5} sx={{ paddingTop: 10 }}>
            <Mui.Stack
              sx={{
                justifyContent: "center",
                padding: 5,
                direction: "column",
                border: `1px solid ${themes.backgroundShadow}`,
                borderRadius: 3,
              }}
            >
              <Mui.Typography>Department name</Mui.Typography>
              <Mui.TextField
                size="medium"
                placeholder="Enter department name"
                sx={{
                  borderWidth: "10px solid black",
                  paddingBottom: 2,
                }}
                {...register("department", {
                  required: "Department is required",
                  maxLength: {
                    value: 30,
                    message: "Maximum length is 30 characters",
                  },
                  minLength: {
                    value: 3,
                    message: "Minimum length is 3 characters",
                  },
                  validate: {
                    noDigitsOrSpecial: (fieldValue) => {
                      if (!/[A-Za-z]/.test(fieldValue)) {
                        return "Department Name must contain at least one alphabet character.";
                      }
                      if (/^\d+$/.test(fieldValue)) {
                        return "Department Name should not contain only digits.";
                      }
                      if (!/^[A-Za-z0-9\s&-]+$/.test(fieldValue)) {
                        return "DepartmentClient Name should only contain alphabets, spaces, -,numbers and & symbol.";
                      }
                      return true;
                    },
                    checkUnique: async (fieldValue) => {
                      if (fieldValue !== "" && !buttonLoader) {
                        setButtonLoader(true);
                        try {
                          const response = await fetch(
                            `${domain}check_department_name/?department_name=${fieldValue.trim()}`,
                            {
                              method: "GET",
                              headers: {
                                "content-type": "application/json",
                                Authorization: `token ${token}`,
                              },
                            }
                          );
                          const res = await response.json();
                          if (response.ok) {
                            if (res.exists) {
                              return "The department name has been already taken.Use a unique department name to proceed further";
                            } else {
                              return true;
                            }
                          } else if (response.status === 409) {
                            sessionHandling();
                          } else {
                            throw new Error(res.error);
                          }
                        } catch (error) {
                          showToast("error", error.message);
                        } finally {
                          setButtonLoader(false);
                        }
                      }
                    },
                  },
                })}
                error={Boolean(errors.department)}
                helperText={errors.department?.message || ""}
                onKeyPress={checkSpecialChar}
                InputProps={{
                  startAdornment: (
                    <Mui.InputAdornment>
                      <Mui.IconButton>
                        <Icons.AddBusiness
                          style={{
                            fontSize: 25,
                            color: themes.blackColor,
                            fontWeight: "bold",
                          }}
                        />
                      </Mui.IconButton>
                    </Mui.InputAdornment>
                  ),
                }}
                fullWidth
              />
              <Mui.Typography>Default Shift Category</Mui.Typography>
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={shiftCategoryName}
                options={shiftCategoryList.map((obj) => ({
                  ...obj,
                  label: obj.name,
                }))}
                sx={{ width: "100%", paddingBottom: 2 }}
                ListboxProps={{ style: { maxHeight: "150px" } }}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.label
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.label}</li>;
                }}
                onChange={(event, value) => {
                  if (value) {
                    setShiftCategoryName(value.name);
                    setValue("shiftCategoryId", value.id);
                  } else {
                    setValue("shiftCategoryId", null);
                    setShiftCategoryName("");
                  }
                }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    value={shiftCategoryName}
                    {...register("shiftCategory", {
                      required: "Shift category is required",
                    })}
                    sx={{
                      background: themes.whiteColor,
                      maxHeight: 120,
                    }}
                    placeholder="Select shift category"
                    size="medium"
                    error={Boolean(errors.shiftCategory)}
                    helperText={errors.shiftCategory?.message || ""}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <GroupsIcon
                            style={{
                              margin: "0 8px",
                              color: "#a6a6a6",
                            }}
                          />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <Mui.Typography>Department Lead</Mui.Typography>
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={departmentLeadName}
                options={departmentLeadList.map((obj) => ({
                  ...obj,
                  label: obj.user.auth_user.username,
                }))}
                sx={{ width: "100%" }}
                ListboxProps={{ style: { maxHeight: "150px" } }}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.label
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.label}</li>;
                }}
                onChange={(event, value) => {
                  if (value) {
                    setDepartmentLeadName(value.user.auth_user.username);
                    setValue("departmentLeadId", value.user.id);
                    getDepartmentProxyList(value.user.auth_user.username);
                  } else {
                    setValue("departmentLeadId", null);
                    setDepartmentLeadName("");
                    getDepartmentProxyList("");
                  }
                }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    value={departmentLeadName}
                    {...register("departmentLead", {
                      required: "Department lead is required",
                    })}
                    sx={{
                      background: themes.whiteColor,
                      maxHeight: 120,
                      paddingBottom: 2,
                    }}
                    placeholder="Select department lead"
                    size="medium"
                    error={Boolean(errors.departmentLead)}
                    helperText={errors.departmentLead?.message || ""}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Person4Icon
                            style={{
                              margin: "0 8px",
                              color: "#a6a6a6",
                            }}
                          />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                    fullWidth
                  />
                )}
              />
              <Mui.Typography>Proxy</Mui.Typography>
              <Mui.Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                value={departmentProxyName}
                options={departmentProxyList.map((obj) => ({
                  ...obj,
                  label: obj.user.auth_user.username,
                }))}
                sx={{ width: "100%", paddingBottom: 2 }}
                ListboxProps={{ style: { maxHeight: "150px" } }}
                filterOptions={(options, state) => {
                  return options.filter((option) =>
                    option.label
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option.label}</li>;
                }}
                onChange={(event, value) => {
                  if (value) {
                    setDepartmentProxyName(value.user.auth_user.username);
                    setValue("departmentProxy", value.user.id);
                  } else {
                    setValue("departmentProxy", null);
                    setDepartmentProxyName("");
                  }
                }}
                renderInput={(params) => (
                  <Mui.TextField
                    {...params}
                    value={departmentProxyName}
                    {...register("proxyName", {
                      required: "Proxy name is required",
                    })}
                    sx={{
                      background: themes.whiteColor,
                      maxHeight: 120,
                    }}
                    placeholder="Select proxy name"
                    size="medium"
                    error={Boolean(errors.proxyName)}
                    helperText={errors.proxyName?.message || ""}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Person4Icon
                            style={{
                              margin: "0 8px",
                              color: "#a6a6a6",
                            }}
                          />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                    fullWidth
                  />
                )}
              />
              <Mui.Grid
                container
                sx={{ justifyContent: "right", paddingBottom: 2 }}
              >
                <CustomButton
                  key={buttonLoader}
                  actionName="Create"
                  typeName="submit"
                  disableAction={buttonLoader}
                />
              </Mui.Grid>
            </Mui.Stack>
          </Mui.Grid>
          <Mui.Grid xs={12} sm={12} md={3} lg={2}></Mui.Grid>
        </Mui.Grid>
      </form>
    </React.Fragment>
  );
};
