import React, { useState, useEffect, useContext } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import moment from "moment";
import { Loader } from "components/Loader";
import { ProjectReportContext } from "../ProRepContext/projectContext";
import sessionHandling from "services/utils/notificationUtils";
import { DateRangePicker } from "pages/MyZone/Status/DateRangePicker";
import { BackArrow } from "components/CustomComponents/BackArrow";
import {
  NavigateNext,
  KeyboardArrowDown,
  DownloadForOffline,
} from "@mui/icons-material";

const UserTaskReport = ({ updateShowReport }) => {
  const { allUsers } = useContext(ProjectReportContext);
  const { domain, token, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(
    userDetails?.role === "Admin" ? "All" : userDetails?.username
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandUserDet, setExpandUserDet] = useState(0);
  const [selectedAssigneeId, setSelectedAssigneeId] = useState(
    userDetails?.role === "Admin" ? "" : userDetails?.id
  );
  const [selectedProject, setSelectedProject] = useState("All");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState(
    `${moment(new Date()).format("DD/MM/YYYY")} - ${moment(new Date()).format(
      "DD/MM/YYYY"
    )}`
  );
  const [allProjectDetails, setAllProjectDetails] = useState([]);
  const [allUserRepDetails, setAllUserRepDetails] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [indTotWorkingHours, setIndTotWorkingHours] = useState("");
  const [indTotPresentHours, setIndTotPresentHours] = useState("");
  const [getDetailedReportData, setGetDetailedReportData] = useState(false);
  const [updatedDetailReportData, setUpdatedDetailsReportData] = useState([]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const selUsrId =
          selectedAssignee === "All" ? 99999 : selectedAssigneeId;

        const response = await fetch(
          `${domain}get-project-based-user/?user_id=${selUsrId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          const AllProjectsData = res.map((obj) => ({
            ...obj,
            label: obj.project_name,
          }));
          setAllProjectDetails(AllProjectsData);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchProjectDetails();
  }, [domain, token, selectedAssignee, selectedAssigneeId]);

  useEffect(() => {
    const getIndividualWorkingHours = async () => {
      try {
        let searchStartDate = startDate.split("/").reverse().join("-");
        let searchEndDate = endDate.split("/").reverse().join("-");
        const response = await fetch(
          `${domain}individual_details_working_hours/?user_id=${selectedAssigneeId}&start_date=${searchStartDate}&end_date=${searchEndDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setIndTotPresentHours(res?.weekly_working_hours);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (selectedAssignee !== "All") {
      getIndividualWorkingHours();
    }
  }, [domain, token, selectedAssignee, selectedAssigneeId, startDate, endDate]);

  useEffect(() => {
    const fetchUsertaskDetails = async () => {
      try {
        setIsLoading(true);
        const selProId = selectedProject === "All" ? 99999 : selectedProjectId;
        const selUsrId =
          selectedAssignee === "All" ? 99999 : selectedAssigneeId;
        let searchStartDate = startDate.split("/").reverse().join("-");
        let searchEndDate = endDate.split("/").reverse().join("-");

        const response = await fetch(
          `${domain}user-task-hours?project_id=${selProId}&user_id=${selUsrId}&task_id=99999&start_date=${searchStartDate}&end_date=${searchEndDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setAllUserRepDetails(res?.tasks);
          setIndTotWorkingHours(res?.total_working_hours);
          setExpandUserDet(0);
          setGetDetailedReportData(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsertaskDetails();
  }, [
    selectedAssignee,
    selectedProject,
    domain,
    token,
    selectedAssigneeId,
    selectedProjectId,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    const getDetailUserReport = () => {
      let overallDetProRepData = [];
      allUserRepDetails.forEach((element) => {
        const mergedDataArray = element?.time_entries?.map((item) => {
          return { ...item, ...element };
        });
        overallDetProRepData = [...overallDetProRepData, ...mergedDataArray];
        return overallDetProRepData;
      });
      setUpdatedDetailsReportData(overallDetProRepData);
    };
    if (getDetailedReportData) {
      getDetailUserReport();
      setGetDetailedReportData(false);
    }
  }, [domain, getDetailedReportData, token, allUserRepDetails]);

  const handleStartDate = async (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedDateRange(`${startDate} - ${endDate}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getSummarizedUserReport = () => {
    let dataPreparation = [];
    allUserRepDetails?.forEach((element) => {
      let overAllData = {
        username: element?.current_assignee?.username,
        startdate: element?.start_date,
        taskcode: element?.task_code,
        taskname: element?.taskname,
        status: element?.work_flow_status?.name
          ? element?.work_flow_status?.name
          : "N/A",
        hours: element?.total_time,
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const items = dataPreparation;
    const TableHead = ["User Name"];
    TableHead.push("Start Date");
    TableHead.push("Task Code");
    TableHead.push("Task Name");
    TableHead.push("Status");
    TableHead.push("Working Hours");

    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(",");
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "SummarizedUserReport.csv";
    document.body.appendChild(element);
    element.click();
  };

  const getDetailedUserReport = () => {
    let dataPreparation = [];
    updatedDetailReportData?.forEach((element) => {
      let overAllData = {
        username: element?.current_assignee?.username,
        startdate: element?.date,
        taskcode: element?.task_code,
        taskname: element?.taskname,
        starttime: element?.start_time,
        endtime: element?.start_time,
        status: element?.task_status ? element?.task_status : "N/A",
        hours: element?.hours,
      };
      dataPreparation = [...dataPreparation, overAllData];
    });
    const items = dataPreparation;
    const TableHead = ["User Name"];
    TableHead.push("Start Date");
    TableHead.push("Task Code");
    TableHead.push("Task Name");
    TableHead.push("Start Time");
    TableHead.push("End Time");
    TableHead.push("Status");
    TableHead.push("Working Hours");

    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(TableHead.join(","));
    csv.unshift(",");
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "DetailedUserReport.csv";
    document.body.appendChild(element);
    element.click();
  };

  return (
    <React.Fragment>
      <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
        <Mui.Grid item xs={12} sm={12} md={12} lg={12}>
          <Mui.Stack direction="row">
            <BackArrow actionFunction={() => updateShowReport(false)} />
            <Mui.Typography
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                mt: 1.5,
              }}
            >
              User Task Report
            </Mui.Typography>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container spacing={1} sx={{ mt: 1, mb: 1 }}>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              allUsers?.find((option) => option?.label === selectedAssignee) ||
              selectedAssignee ||
              ""
            }
            options={allUsers}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options.filter((option) =>
                option.label
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option.label}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.label) {
                setSelectedAssignee(value.label);
                setSelectedProject("All");
                setSelectedAssigneeId(value.id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="User Name"
                sx={{ background: "white" }}
                name="username"
                placeholder="Select User Name"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3} lg={2}>
          <Mui.Autocomplete
            disablePortal
            id="combo-box-demo"
            size="small"
            sx={{ minWidth: "100%" }}
            value={
              allProjectDetails?.find(
                (option) => option?.label === selectedProject
              ) ||
              selectedProject ||
              ""
            }
            options={allProjectDetails}
            ListboxProps={{
              style: {
                maxHeight: "150px",
              },
            }}
            filterOptions={(options, state) => {
              return options?.filter((option) =>
                option?.label
                  ?.toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              );
            }}
            renderOption={(props, option) => {
              return <li {...props}>{option?.label}</li>;
            }}
            onChange={(event, value) => {
              if (value && value?.label) {
                setSelectedProject(value.label);
                setSelectedProjectId(value.project_id);
              }
            }}
            renderInput={(params) => (
              <Mui.TextField
                {...params}
                size="small"
                label="Project"
                sx={{ background: "white" }}
                name="projects"
                placeholder="Select Project"
              />
            )}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={6} md={3.5} lg={3}>
          <DateRangePicker
            handleStartDate={handleStartDate}
            selectedDateRange={selectedDateRange}
          />
        </Mui.Grid>
        <Mui.Grid item lg={3.5}></Mui.Grid>
        <Mui.Grid item xs={2} sm={2} md={2} lg={1.5}>
          <Mui.IconButton
            onClick={(event) => {
              handleMenuOpen(event);
            }}
            disabled={allUserRepDetails?.length === 0 ? true : false}
          >
            <DownloadForOffline
              size="large"
              sx={{ color: "#25B7D3", fontSize: 40, mt: -1 }}
            />
          </Mui.IconButton>
          <Mui.Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            elevation={1}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <>
              <Mui.Stack>
                <Mui.MenuItem
                  key={"SummarizedReport"}
                  onClick={() => getSummarizedUserReport()}
                >
                  Summarized Report
                </Mui.MenuItem>
                <Mui.MenuItem
                  key={"DetailedReport"}
                  onClick={() => getDetailedUserReport()}
                >
                  Detailed Report
                </Mui.MenuItem>
              </Mui.Stack>
            </>
          </Mui.Menu>
        </Mui.Grid>
      </Mui.Grid>

      {isLoading ? (
        <Loader info="Loading..." />
      ) : (
        <>
          {allUserRepDetails?.length !== 0 ? (
            <Mui.Grid
              container
              sx={{
                paddingRight: 1,
              }}
            >
              <Mui.TableContainer
                component={Mui.Paper}
                style={{ paddingTop: 4, paddingBottom: 2 }}
                sx={{
                  mt: 2,
                  borderBottom: "1.5px solid transparent",
                }}
              >
                <Mui.Table>
                  <Mui.TableHead>
                    <Mui.TableRow
                      maxWidth="xl"
                      align="left"
                      sx={{ backgroundColor: themes.primary }}
                    >
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                        }}
                      ></Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "15%",
                        }}
                      >
                        Date
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                        }}
                      >
                        Task Id
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: selectedProject === "All" ? "30%" : "50%",
                        }}
                      >
                        Task Name
                      </Mui.TableCell>
                      {selectedProject === "All" ? (
                        <Mui.TableCell
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: 100,
                            width: "20%",
                          }}
                        >
                          Project Name
                        </Mui.TableCell>
                      ) : null}
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                        }}
                      >
                        Status
                      </Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          maxWidth: 100,
                          width: "10%",
                        }}
                      >
                        Hours
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableHead>
                  <Mui.TableBody>
                    {allUserRepDetails
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <>
                          <Mui.TableRow
                            maxWidth="xl"
                            align="left"
                            sx={{
                              border: `1px solid ${themes.formBackground}`,
                              cursor: "pointer",
                            }}
                            hover={true}
                          >
                            <Mui.TableCell align="left">
                              <Mui.IconButton
                                size="small"
                                sx={{ marginRight: 2 }}
                                onClick={() => {
                                  setExpandUserDet(
                                    item?.id === expandUserDet ? 0 : item?.id
                                  );
                                }}
                              >
                                {expandUserDet === item?.id ? (
                                  <KeyboardArrowDown
                                    size="small"
                                    sx={{ stroke: 2, color: themes.blackColor }}
                                  />
                                ) : (
                                  <NavigateNext
                                    size="small"
                                    sx={{ stroke: 2, color: themes.blackColor }}
                                  />
                                )}
                              </Mui.IconButton>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.start_date ? item?.start_date : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.task_code ? item?.task_code : "N/A"}
                              </Mui.Typography>
                            </Mui.TableCell>
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Tooltip title={item?.taskname}>
                                <Mui.Typography
                                  sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.taskname}
                                </Mui.Typography>
                              </Mui.Tooltip>
                            </Mui.TableCell>
                            {selectedProject === "All" ? (
                              <Mui.TableCell
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  maxWidth: 100,
                                }}
                              >
                                <Mui.Tooltip title={item?.project?.projectname}>
                                  <Mui.Typography
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {item?.project?.projectname}
                                  </Mui.Typography>
                                </Mui.Tooltip>
                              </Mui.TableCell>
                            ) : null}
                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.work_flow_status?.name}
                              </Mui.Typography>
                            </Mui.TableCell>

                            <Mui.TableCell
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                maxWidth: 100,
                              }}
                            >
                              <Mui.Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.total_time ? item?.total_time : "00:00"}
                              </Mui.Typography>
                            </Mui.TableCell>
                          </Mui.TableRow>

                          {expandUserDet === item?.id && (
                            <Mui.TableRow>
                              <Mui.TableCell colSpan={7}>
                                <Mui.TableContainer
                                  sx={{
                                    paddingRight: 5,
                                    paddingLeft: 5,
                                  }}
                                >
                                  {item?.time_entries?.length !== 0 ? (
                                    <Mui.Table className="border">
                                      <Mui.TableHead
                                        sx={{
                                          backgroundColor: themes.primary,
                                        }}
                                      >
                                        <Mui.TableRow>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                              width: "15%",
                                            }}
                                          >
                                            Date
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                              width: "20%",
                                            }}
                                          >
                                            User Name
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                              width: "15%",
                                            }}
                                          >
                                            Status
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              width: "17%",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                            }}
                                          >
                                            Start Time
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                              width: "17%",
                                            }}
                                          >
                                            End Time
                                          </Mui.TableCell>
                                          <Mui.TableCell
                                            sx={{
                                              color: themes.blackColor,
                                              fontWeight: "bold",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              maxWidth: 100,
                                              width: "16%",
                                            }}
                                          >
                                            Hours
                                          </Mui.TableCell>
                                        </Mui.TableRow>
                                      </Mui.TableHead>
                                      <Mui.TableBody>
                                        {item?.time_entries?.map(
                                          (rowentry, index) => (
                                            <Mui.TableRow
                                              key={index}
                                              hover={true}
                                            >
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  maxWidth: 100,
                                                }}
                                              >
                                                {rowentry?.date
                                                  ? rowentry?.date
                                                  : "N/A"}
                                              </Mui.TableCell>
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  maxWidth: 100,
                                                }}
                                              >
                                                <Mui.Typography
                                                  sx={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  {rowentry?.user_name
                                                    ? rowentry?.user_name
                                                    : "N/A"}
                                                </Mui.Typography>
                                              </Mui.TableCell>
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  maxWidth: 100,
                                                }}
                                              >
                                                <Mui.Typography
                                                  sx={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  {rowentry?.task_status
                                                    ? rowentry?.task_status
                                                    : "N/A"}
                                                </Mui.Typography>
                                              </Mui.TableCell>
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  maxWidth: 100,
                                                }}
                                              >
                                                <Mui.Typography
                                                  sx={{
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  {rowentry?.start_time
                                                    ? rowentry?.start_time
                                                    : "N/A"}
                                                </Mui.Typography>
                                              </Mui.TableCell>
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                }}
                                              >
                                                {rowentry?.end_time
                                                  ? rowentry?.end_time
                                                  : "N/A"}
                                              </Mui.TableCell>
                                              <Mui.TableCell
                                                sx={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                }}
                                              >
                                                {rowentry?.hours
                                                  ? rowentry?.hours
                                                  : "00:00"}
                                              </Mui.TableCell>
                                            </Mui.TableRow>
                                          )
                                        )}
                                      </Mui.TableBody>
                                    </Mui.Table>
                                  ) : (
                                    <Mui.Stack
                                      sx={{ padding: 0.5 }}
                                      spacing={2}
                                      fullWidth
                                    >
                                      <Mui.Alert severity="info">
                                        There is a no available task
                                      </Mui.Alert>
                                    </Mui.Stack>
                                  )}
                                </Mui.TableContainer>
                              </Mui.TableCell>
                            </Mui.TableRow>
                          )}
                        </>
                      ))}
                  </Mui.TableBody>
                  <Mui.TableBody>
                    {selectedAssignee !== "All" ? (
                      <Mui.TableRow>
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell></Mui.TableCell>
                        <Mui.TableCell
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Mui.Typography>Total Present Hours</Mui.Typography>
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {" "}
                          {indTotPresentHours ? indTotPresentHours : "00:00:00"}
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ) : null}
                    <Mui.TableRow>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell></Mui.TableCell>
                      <Mui.TableCell
                        sx={{
                          color: themes.blackColor,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Mui.Typography>Total Working Hours</Mui.Typography>
                      </Mui.TableCell>
                      <Mui.TableCell>
                        {" "}
                        {indTotWorkingHours ? indTotWorkingHours : "00:00:00"}
                      </Mui.TableCell>
                    </Mui.TableRow>
                  </Mui.TableBody>
                </Mui.Table>
                {allUserRepDetails.length > 10 ? (
                  <Mui.TablePagination
                    className="custom-pagination"
                    component="div"
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    count={allUserRepDetails.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                ) : null}
              </Mui.TableContainer>
            </Mui.Grid>
          ) : (
            <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
              <Mui.Alert severity="info">
                There is a no available task
              </Mui.Alert>
            </Mui.Stack>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default UserTaskReport;
