import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { Box } from "@material-ui/core";
import { themes } from "services/constants";
import { Loader } from "components/Loader";
import moment from "moment";
import { Close } from "@mui/icons-material";
import { CustomButton } from "components/CustomComponents/CustomButton";

const BootstrapDialog = Mui.styled(Mui.Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "20px",
  },
}));

function FuncDialogTitle(props) {
  const { children, onClose, title, ...other } = props;

  return (
    <Mui.DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <Mui.IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close sx={{ color: themes.blackColor }} />
        </Mui.IconButton>
      ) : null}
    </Mui.DialogTitle>
  );
}

export const Weekly = ({
  loading,
  weeklyShiftList,
  listOfDate,
  filterChanges,
  setFilterChanges,
  shiftData,
  updateWeeklyShift,
}) => {
  const [page, setLeavePage] = useState(0);
  const [rowsPerPage, setLeaveRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setLeavePage(newPage);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [userShiftsDet, setUserShiftsDet] = useState([]);
  const [curShiftDetail, setCurShiftDetail] = useState([]);
  const [shiftName, setShiftName] = useState("");
  const [shiftId, setShiftId] = useState("");

  const handleChangeRowsPerPage = (event) => {
    setLeaveRowsPerPage(parseInt(event.target.value, 10));
    setLeavePage(0);
  };
  useEffect(() => {
    if (filterChanges) {
      setFilterChanges(false);
      setLeavePage(0);
    }
  }, [filterChanges, setFilterChanges]);

  const checkDateWithMoment = (inputDate) => {
    const givenDate = moment(inputDate, "DD-MM-YYYY");
    const currentDate = moment();
    if (givenDate.isSameOrAfter(currentDate, "day")) {
      return true;
    } else {
      return false;
    }
  };

  const updateIndividualShift = async () => {
    const shift_assignments = [
      {
        shift_id: shiftId ? shiftId : curShiftDetail?.shift_id,
        date: moment(curShiftDetail?.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
        is_week_off: shiftName === "Weekoff" ? true : false,
      },
    ];

    updateWeeklyShift(userShiftsDet?.user_id, shift_assignments);
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      {loading === true ? (
        <Box>
          <Loader info="Loading..." />
        </Box>
      ) : (
        <>
          <Mui.TableContainer
            component={Mui.Paper}
            sx={{
              mt: 2,
              width: "100%",
              border: 1,
              borderRadius: 5,
              borderColor: themes.primary,
            }}
          >
            <Mui.Table>
              <Mui.TableHead>
                <Mui.TableRow>
                  <Mui.TableCell
                    sx={{
                      color: themes.blackColor,
                      fontWeight: "bold",
                      border: 1,
                      borderColor: themes.primary,
                    }}
                  >
                    Employee Name
                  </Mui.TableCell>

                  {listOfDate?.map((value) => (
                    <Mui.TableCell
                      sx={{ border: 1, borderColor: themes.primary }}
                    >
                      <Mui.Stack
                        direction="column"
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <Mui.Typography
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                            fontSize: 24,
                          }}
                        >
                          {moment(value, "YYYY-MM-DD").format("DD")}
                        </Mui.Typography>
                        <Mui.Typography
                          sx={{
                            color: themes.blackColor,
                            fontWeight: "bold",
                            fontSize: 12,
                          }}
                        >
                          {moment(value, "YYYY-MM-DD").format("ddd, MMM")}
                        </Mui.Typography>
                      </Mui.Stack>
                    </Mui.TableCell>
                  ))}
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {weeklyShiftList?.length === 0 ? (
                  <Mui.Alert severity="info" sx={{ width: "100%", margin: 2 }}>
                    No data found
                  </Mui.Alert>
                ) : (
                  weeklyShiftList
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((shiftList, index) => (
                      <React.Fragment>
                        <Mui.TableRow key={index}>
                          <Mui.TableCell
                            sx={{ border: 1, borderColor: themes.primary }}
                          >
                            {shiftList?.user_name}
                          </Mui.TableCell>
                          {shiftList?.shifts.map((shift, i) => (
                            <Mui.TableCell
                              sx={{
                                fontSize: 13,
                                border: 1,
                                borderColor: themes.primary,
                                backgroundColor: checkDateWithMoment(
                                  shift?.date
                                )
                                  ? themes.whiteColor
                                  : "#ededed",
                              }}
                              onClick={() => {
                                if (checkDateWithMoment(shift?.date)) {
                                  setUserShiftsDet(shiftList);
                                  setCurShiftDetail(shift);
                                  setShiftName(shift?.shift_name);
                                  setShiftId(shift?.id);
                                  setOpenDialog(true);
                                }
                              }}
                              style={{
                                cursor: checkDateWithMoment(shift?.date)
                                  ? "pointer"
                                  : "not-allowed",
                                color: checkDateWithMoment(shift?.date)
                                  ? themes.blackColor
                                  : "gray",
                              }}
                            >
                              <Mui.Stack
                                direction="column"
                                width={"100%"}
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                <Mui.Typography
                                  sx={{ fontSize: 12, fontWeight: "bold" }}
                                >
                                  {shift?.shift_name
                                    ? shift?.shift_name
                                    : "Not Joined"}
                                </Mui.Typography>
                                {shift?.shift_name === null ||
                                shift?.shift_name === "Weekoff" ? (
                                  ""
                                ) : shift?.shift_start_time &&
                                  shift?.shift_end_time ? (
                                  <>
                                    <Mui.Typography sx={{ fontSize: 12 }}>
                                      {moment(
                                        shift?.shift_start_time,
                                        "HH:mm:SS"
                                      ).format("h:mm a")}
                                      {" to "}{" "}
                                      {moment(
                                        shift?.shift_end_time,
                                        "HH:mm:SS"
                                      ).format("h:mm a")}
                                    </Mui.Typography>
                                  </>
                                ) : null}
                              </Mui.Stack>
                            </Mui.TableCell>
                          ))}
                        </Mui.TableRow>
                      </React.Fragment>
                    ))
                )}
              </Mui.TableBody>
            </Mui.Table>
          </Mui.TableContainer>
          {weeklyShiftList.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={weeklyShiftList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {openDialog ? (
            <div>
              <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={openDialog}
              >
                <FuncDialogTitle
                  id="customized-dialog-title"
                  align="center"
                  sx={{
                    minWidth: {
                      xs: "90%",
                      sm: "80%",
                      md: "580px",
                    },
                    color: themes.headLine,
                    fontWeight: 1000,
                    fontSize: {
                      xs: "18px",
                      sm: "20px",
                      md: "23px",
                    },
                    background: themes.primary,
                  }}
                  onClose={() => {
                    setOpenDialog(false);
                  }}
                  title={"Edit Shift Details"}
                >
                  {"Edit Shift Details"}
                </FuncDialogTitle>
                <Mui.DialogContent dividers>
                  <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography>Employee Name</Mui.Typography>
                    <Mui.TextField
                      type="text"
                      size="small"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontSize: 14,
                        width: "100%",
                      }}
                      InputProps={{
                        inputProps: { maxLength: 512 },
                      }}
                      value={userShiftsDet?.user_name}
                      disabled={true}
                      fullWidth
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography>Date</Mui.Typography>
                    <Mui.TextField
                      type="date"
                      size="small"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        fontSize: 14,
                        width: "100%",
                      }}
                      InputProps={{
                        inputProps: { maxLength: 512 },
                      }}
                      value={moment(curShiftDetail?.date, "DD-MM-YYYY").format(
                        "YYYY-MM-DD"
                      )}
                      disabled={true}
                      fullWidth
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={12} sx={{ paddingBottom: 1 }}>
                    <Mui.Typography>Shift Name</Mui.Typography>
                    <Mui.Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      size="small"
                      value={shiftName}
                      options={shiftData}
                      filterOptions={(options, state) => {
                        return options?.filter((option) =>
                          option?.label
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase())
                        );
                      }}
                      renderOption={(props, option) => {
                        return <li {...props}>{option?.label}</li>;
                      }}
                      ListboxProps={{
                        style: {
                          maxHeight: "150px",
                          overflow: "auto",
                        },
                      }}
                      sx={{ minWidth: "100%" }}
                      onChange={(event, value) => {
                        if (value) {
                          setShiftId(value.id);
                          setShiftName(value.label);
                        } else {
                          setShiftId("");
                          setShiftName("");
                        }
                      }}
                      renderInput={(params) => (
                        <Mui.TextField
                          {...params}
                          value={shiftName}
                          sx={{
                            background: themes.whiteColor,
                          }}
                          placeholder="Select shift"
                          size="small"
                        />
                      )}
                    />
                  </Mui.Grid>

                  <Mui.Grid container>
                    <Mui.Grid xs={8}></Mui.Grid>
                    <Mui.Grid
                      xs={4}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <CustomButton
                        actionFuntion={() => {
                          setOpenDialog(false);
                        }}
                        actionName="Cancel"
                        typeName="button"
                      />
                      &nbsp;
                      <CustomButton
                        actionName="Update"
                        typeName="submit"
                        actionFuntion={updateIndividualShift}
                      />
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.DialogContent>
              </BootstrapDialog>
            </div>
          ) : null}
        </>
      )}
    </React.Fragment>
  );
};
