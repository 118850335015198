import React, { useState, useEffect } from "react";
import { CustomButton } from "components/CustomComponents/CustomButton";
import * as Mui from "@mui/material";
import { useFetch } from "services/hooks/useFetch";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { useDispatch } from "react-redux";
import { PayingUnit } from "./PayScreen/PayingUnit";
import { GroupUnit } from "./GroupScreen/GroupUnit";
import { AssignUnit } from "./AssignScreen/AssignUnit";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { themes, defaultAllSelection } from "services/constants";
import sessionHandling from "services/utils/notificationUtils";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
export const WorkUnit = () => {
  const { departmentId, departmentName } = useSelector(
    (state) => state.userReducer
  );

  const [workUnit, setWorkUnit] = useState("Pay");
  const [selectComponent, setSelectComponent] = useState("Pay");
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const [payUnit, setPayUnit] = useState(false);
  const [editWorkUnitPay, setEditWorkUnitPay] = useState(false);
  const [groupUnit, setGroupUnit] = useState(false);
  const [editGroupUnit, setEditGroupUnit] = useState(false);
  const [assignGroupUnit, setAssignGroupUnit] = useState(false);
  const [editAssignUnit, setEditAssignUnit] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const {
    result: workUnitGroup,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}work_unit_groups/?department=${departmentId}`);
  const dispatch = useDispatch();
  const handleChange = (event, newAlignment) => {
    setWorkUnit(newAlignment);
    dispatch(setGlobalSearchValue(""));
  };

  useEffect(() => {
    dispatch(setDepartmentName("All"));
    dispatch(setDepartmentId(99999));
  }, [dispatch]);

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        const response = await fetch(`${domain}department/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        });
        const res = await response.json();
        if (response.ok) {
          const allDepartment = {
            id: defaultAllSelection,
            department: "All",
          };
          setDepartmentData([allDepartment, ...res]);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    };
    fetchDepartmentData();
  }, [domain, token]);

  const renderComponent = () => {
    switch (selectComponent) {
      case "Pay":
        return (
          <PayingUnit
            payUnit={payUnit}
            setPayUnit={setPayUnit}
            editWorkUnitPay={editWorkUnitPay}
            setEditWorkUnitPay={setEditWorkUnitPay}
          />
        );
      case "Group":
        return (
          <GroupUnit
            groupUnit={groupUnit}
            setGroupUnit={setGroupUnit}
            editGroupUnit={editGroupUnit}
            setEditGroupUnit={setEditGroupUnit}
            departmentId={departmentId}
            workUnitGroup={workUnitGroup}
            reCallApi={reCallApi}
            loading={loading}
          />
        );
      case "Assign":
        return (
          <AssignUnit
            assignGroupUnit={assignGroupUnit}
            setAssignGroupUnit={setAssignGroupUnit}
            editAssignUnit={editAssignUnit}
            setEditAssignUnit={setEditAssignUnit}
          />
        );

      default:
        break;
    }
  };

  const updateDepartmentName = (event, data) => {
    if (data) {
      dispatch(setDepartmentName(data.label));
      dispatch(setDepartmentId(data.id));
      localStorage.setItem("approveLeaveDepartmentId", data.id);
      localStorage.setItem("approveLeaveDepartmentName", data.label);
      reCallApi(true);
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2, paddingLeft: 1 }}>
        {payUnit ||
        editWorkUnitPay ||
        groupUnit ||
        editGroupUnit ||
        assignGroupUnit ||
        editAssignUnit ? null : (
          <Mui.Grid item xs={5}>
            <Mui.Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: 400,
                borderRadius: 3,
                height: 55,
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Mui.ToggleButtonGroup
                value={workUnit}
                exclusive
                onChange={handleChange}
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  borderRadius: 3,
                  backgroundColor: themes.lightBrown,
                }}
              >
                <Mui.ToggleButton
                  value="Pay"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,

                    fontWeight: 600,
                    border: "none",
                    color:
                      workUnit === "Pay"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      workUnit === "Pay"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        workUnit === "Pay"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        workUnit === "Pay"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => setSelectComponent("Pay")}
                >
                  Pay
                </Mui.ToggleButton>
                <Mui.ToggleButton
                  value="Group"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    fontWeight: 600,
                    borderRadius: 3,
                    border: "none",
                    color:
                      workUnit === "Group"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      workUnit === "Group"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        workUnit === "Group"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        workUnit === "Group"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => setSelectComponent("Group")}
                >
                  Group
                </Mui.ToggleButton>
                {/* <Mui.ToggleButton
                  value="Assign"
                  sx={{
                    flex: 1,
                    textTransform: "capitalize",
                    fontSize: 18,
                    fontWeight: 600,
                    borderRadius: 3,
                    border: "none",
                    color:
                      workUnit === "Assign"
                        ? themes.whiteColor
                        : themes.blackColor,
                    backgroundColor:
                      workUnit === "Assign"
                        ? themes.blackColor
                        : themes.lightBrown,
                    "&.Mui-selected": {
                      backgroundColor: themes.blackColor,
                      color: themes.whiteColor,
                      "&:hover": {
                        backgroundColor: themes.blackColor,
                        color: themes.whiteColor,
                      },
                    },
                    "&:hover": {
                      backgroundColor:
                        workUnit === "Assign"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        workUnit === "Assign"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                    "&:focus": {
                      backgroundColor:
                        workUnit === "Assign"
                          ? themes.blackColor
                          : themes.lightBrown,
                      color:
                        workUnit === "Assign"
                          ? themes.whiteColor
                          : themes.blackColor,
                    },
                  }}
                  onClick={() => setSelectComponent("Assign")}
                >
                  Assign
                </Mui.ToggleButton> */}
              </Mui.ToggleButtonGroup>
            </Mui.Box>
          </Mui.Grid>
        )}

        <Mui.Grid item xs={7}>
          <Mui.Grid xs={7}>
            {payUnit ||
            editWorkUnitPay ||
            groupUnit ||
            editGroupUnit ||
            assignGroupUnit ||
            editAssignUnit ? (
              <Mui.Stack direction="row">
                <BackArrow
                  actionFunction={() => {
                    setEditWorkUnitPay(false);
                    setPayUnit(false);
                    setGroupUnit(false);
                    setEditGroupUnit(false);
                    setAssignGroupUnit(false);
                    setEditAssignUnit(false);
                    dispatch(setGlobalSearchValue(""));
                  }}
                />
                <Mui.Typography
                  sx={{ fontWeight: "bold", fontSize: 25, paddingTop: 1 }}
                >
                  {(() => {
                    if (selectComponent === "Pay") {
                      return editWorkUnitPay
                        ? "Work Unit / Edit WorkUnit Pay"
                        : "Work Unit / Add WorkUnit Pay";
                    } else if (selectComponent === "Group") {
                      return editGroupUnit
                        ? "Work Unit / Edit WorkUnit Group"
                        : "Work Unit / Add WorkUnit Group";
                    } else {
                      return editAssignUnit
                        ? "Work Unit / Edit WorkUnit"
                        : "Work Unit /  Assign WorkUnit";
                    }
                  })()}
                </Mui.Typography>
              </Mui.Stack>
            ) : null}
          </Mui.Grid>
          {payUnit ||
          editWorkUnitPay ||
          selectComponent === "Group" ||
          selectComponent === "Assign" ? null : (
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={8}></Mui.Grid>
              <Mui.Grid
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 2,
                }}
              >
                <CustomButton
                  actionFuntion={() => setPayUnit(true)}
                  actionName="Add WorkUnit Pay"
                  typeName="button"
                />
              </Mui.Grid>
            </Mui.Grid>
          )}

          {groupUnit ||
          editGroupUnit ||
          selectComponent === "Pay" ||
          selectComponent === "Assign" ? null : (
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={6}></Mui.Grid>
              <Mui.Grid
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 0.5,
                }}
              >
                <Mui.Grid container>
                  <Mui.Grid xs={6} sx={{ paddingRight: 1 }}>
                    <CustomAutoComplete
                      dataList={departmentData?.map((obj) => ({
                        ...obj,
                        label: obj.department,
                      }))}
                      selectedValue={departmentName}
                      updateDataFunction={updateDepartmentName}
                      placeHolder="Select department name"
                      label="Select Department Name"
                    />
                  </Mui.Grid>
                  <Mui.Grid xs={6}>
                    <CustomButton
                      actionFuntion={() => setGroupUnit(true)}
                      actionName="Add WorkUnit Group"
                      typeName="button"
                    />
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Grid>
            </Mui.Grid>
          )}

          {assignGroupUnit ||
          editAssignUnit ||
          selectComponent === "Pay" ||
          selectComponent === "Group" ? null : (
            <Mui.Grid container sx={{ paddingTop: 1 }}>
              <Mui.Grid xs={8}></Mui.Grid>
              <Mui.Grid
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  paddingRight: 2,
                }}
              >
                <CustomButton
                  actionFuntion={() => setAssignGroupUnit(true)}
                  actionName="Assign WorkUnit Group"
                  typeName="button"
                />
              </Mui.Grid>
            </Mui.Grid>
          )}
        </Mui.Grid>
      </Mui.Grid>
      {renderComponent()}
    </React.Fragment>
  );
};
