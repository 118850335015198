import React, { useEffect, useState } from "react";
import {
  DownloadForOffline,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useSelector } from "react-redux";
import { showToast } from "services/utils/Status";
import { Loader } from "components/Loader";
import { themes } from "services/constants";
import { getColorWorkingHours } from "services/utils/Status";
import { DateRangePicker } from "./DateRangePicker";
import { GlobalDialogBox } from "components/GlobalDialogBox";
import moment from "moment";
import * as Mui from "@mui/material";
import { leaveManagementError } from "services/constants/ErrorMessages";
import { leaveSuccess } from "services/constants/SuccessMessages";
import sessionHandling from "services/utils/notificationUtils";

const keys = [
  "date",
  "shift",
  "type",
  "status",
  "check_in_time",
  "check_out_time",
  "Leave_credit_hours",
  "break_hours",
  "working_hours",
  "over_time",
  "total_permission_taken",
  "Late_days",
];

export const IndividualWorkingHours = () => {
  const { domain, token, globalSearchValue, userDetails } = useSelector(
    (state) => state.tokenReducer
  );
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [breakInTime, setBreakInTime] = useState(null);
  const [breakOutTime, setBreakOutTime] = useState(null);
  const [breakInError, setBreakInError] = useState(null);
  const [breakOutError, setBreakOutError] = useState(null);
  const [breakPopup, setBreakPopup] = useState(false);
  const [breakDayDetails, setBreakDayDetails] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [workingHoursDetails, setWorkingHoursDetails] = useState([]);
  const [handleLoading, setHandleLoading] = useState(false);
  const [expandRow, setExpandRow] = useState(null);
  const [userWeeklyReport, setUserWeeklyReport] = useState([]);
  const [ediBreakErrors, setEditBreakErrors] = useState("");
  const [masterDataForSearch, setMasterDataForSearch] = useState([]);
  const [showBreakDetails, setShowBreakDetails] = useState(true);

  useEffect(() => {
    const getWorkingHours = async () => {
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
      const mostRecentMonday = new Date(
        currentDate.setDate(currentDate.getDate() + diffToMonday)
      );
      const currentDateDetails = new Date();
      const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
      const currentWeekSunday = new Date(
        currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
      );
      let searchStartDate = moment(new Date(mostRecentMonday))
        .format("DD/MM/YYYY")
        .split("/")
        .reverse()
        .join("-");
      let searchEndDate = moment(new Date(currentWeekSunday))
        .format("DD/MM/YYYY")
        .split("/")
        .reverse()
        .join("-");
      setHandleLoading(true);
      try {
        const response = await fetch(
          `${domain}individual_details_working_hours/?user_id=${userDetails.id}&start_date=${searchStartDate}&end_date=${searchEndDate}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setUserWeeklyReport(res);
          setMasterDataForSearch(
            res?.checkin_details ? res?.checkin_details : []
          );
          setWorkingHoursDetails(
            res?.checkin_details ? res?.checkin_details : []
          );
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
        setHandleLoading(false);
        setShowBreakDetails(false);
      } catch (error) {
        showToast("error", error.message);
      }
    };
    if (showBreakDetails) {
      getWorkingHours();
    }
  }, [domain, setShowBreakDetails, showBreakDetails, token, userDetails.id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const diffToMonday = currentDay === 0 ? -6 : 1 - currentDay;
    const mostRecentMonday = new Date(
      currentDate.setDate(currentDate.getDate() + diffToMonday)
    );
    const currentDateDetails = new Date();
    const diffToSunday = currentDay === 0 ? 0 : 7 - currentDay;
    const currentWeekSunday = new Date(
      currentDateDetails.setDate(currentDateDetails.getDate() + diffToSunday)
    );
    setSelectedDateRange(
      `${moment(new Date(mostRecentMonday.toISOString().split("T")[0])).format(
        "DD/MM/YYYY"
      )} - ${moment(
        new Date(currentWeekSunday.toISOString().split("T")[0])
      ).format("DD/MM/YYYY")}`
    );
  }, []);

  const handleStartDate = async (startDate, endDate) => {
    setSelectedDateRange(`${startDate} - ${endDate}`);
    let searchStartDate = startDate.split("/").reverse().join("-");
    let searchEndDate = endDate.split("/").reverse().join("-");
    setHandleLoading(true);
    try {
      const response = await fetch(
        `${domain}individual_details_working_hours/?user_id=${userDetails.id}&start_date=${searchStartDate}&end_date=${searchEndDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${token}`,
          },
        }
      );
      const res = await response.json();
      if (response.ok) {
        setUserWeeklyReport(res);
        setMasterDataForSearch(res.checkin_details);
        setWorkingHoursDetails(res.checkin_details);
        setHandleLoading(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
      setHandleLoading(false);
      setShowBreakDetails(false);
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleTableClick = (date) => {
    setExpandRow(date);
  };

  const DownloadData = () => {
    if (workingHoursDetails.length !== 0) {
      let dataPreparation = [];
      workingHoursDetails?.forEach((element) => {
        let overAllData = {
          date: element.date,
          shift: element.shift,
          overTime: element.over_time,
          checkin: element.check_in_time_formatted,
          checkout: element.check_out_time_formatted,
          break: element.break_hours,
          workingHours: element.working_hours,
          leaveCredit: element.Leave_credit_hours,
          status: element.status,
        };
        dataPreparation = [...dataPreparation, overAllData];
      });
      const username = ["UserName"];
      const DateRange = ["DateRange"];
      const WorkingHours = ["WorkingHours"];
      const Permission = ["Permissions"];
      const Late = ["Late"];
      const Break = ["Break"];
      const Leave = ["Leave"];
      const Absent = ["Absent"];
      Permission.push(userWeeklyReport?.total_permission_days);
      Late.push(userWeeklyReport?.total_late_days);
      Leave.push(userWeeklyReport?.personal_leave);
      Break.push(userWeeklyReport?.total_break_hours);
      Absent.push(userWeeklyReport?.absent_days);
      username.push(userDetails?.username);
      DateRange.push(selectedDateRange);
      WorkingHours.push(userWeeklyReport.weekly_working_hours);
      const items = dataPreparation;
      const TableHead = ["Date"];
      TableHead.push("Shift");
      TableHead.push("Over Time");
      TableHead.push("CheckInTime");
      TableHead.push("CheckOutTime");
      TableHead.push("BreakHours");
      TableHead.push("WorkingHours");
      TableHead.push("LeaveCreditHours");
      TableHead.push("Status");
      const replacer = (key, value) => (value === null ? "" : value);
      const header = Object.keys(items[0]).filter(
        (field) =>
          field !== "break_details" &&
          field !== "permission_details" &&
          field !== "id" &&
          field !== "total_permission_taken" &&
          field !== "total_permission_taken" &&
          field !== "Late_days" &&
          field !== "type"
      );
      let csv = items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      );
      csv.unshift(TableHead.join(","));
      csv.unshift(Absent.join(","));
      csv.unshift(Break.join(","));
      csv.unshift(Late.join(","));
      csv.unshift(Leave.join(","));
      csv.unshift(Permission.join(","));
      csv.unshift(WorkingHours.join(","));
      csv.unshift(DateRange.join(","));
      csv.unshift(username.join(","));
      csv = csv.join("\r\n");
      const ConvertData = csv;
      const element = document.createElement("a");
      const file = new Blob([ConvertData], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = "WorkingHours.csv";
      document.body.appendChild(element);
      element.click();
    } else {
      showToast("error", "No data available");
    }
  };
  const handleCloseDialog = () => {
    setBreakDayDetails(null);
    setBreakPopup(false);
    setBreakOutError(null);
    setBreakInError(null);
    setBreakInTime(null);
    setBreakOutTime(null);
  };

  const createNewBreaks = async () => {
    const breakInData = getTwentyFourHourTime(breakInTime);
    const breakOutData = getTwentyFourHourTime(breakOutTime);
    const checkBreakOutTime =
      parseInt(breakOutData.split(":")[0]) * 60 +
      parseInt(breakOutData.split(":")[1]);
    const checkBreakInTime =
      parseInt(breakInData.split(":")[0]) * 60 +
      parseInt(breakInData.split(":")[1]);
    if (breakInTime && breakOutTime) {
      if (checkBreakInTime > checkBreakOutTime) {
        try {
          const response = await fetch(`${domain}break-user/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
            body: JSON.stringify({
              break_in: getTwentyFourHourTime(breakInTime),
              break_out: getTwentyFourHourTime(breakOutTime),
              user: userDetails.id,
              date: breakDayDetails?.date.split("-").reverse().join("-"),
            }),
          });
          const res = await response.json();
          if (response.ok) {
            setShowBreakDetails(true);
            handleCloseDialog();
            showToast("success", leaveSuccess.breakInOutTime);
            setEditBreakErrors("");
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      } else {
        setEditBreakErrors(leaveManagementError.breakError);
      }
    } else {
      if (breakInTime === null) {
        setBreakInError(leaveManagementError.breakInError);
      } else {
        setBreakOutError(leaveManagementError.breakOutError);
      }
    }
  };

  const getTwentyFourHourTime = (time) => {
    if (time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes + ":00";
    }
  };
  const validate = () => {
    if (breakInTime === null) {
      setBreakInError(leaveManagementError.breakInError);
    } else {
      setBreakInError("");
    }
    if (breakOutTime === null) {
      setBreakOutError(leaveManagementError.breakOutError);
    } else {
      setBreakOutError("");
    }
  };

  useEffect(() => {
    const filteredResults = masterDataForSearch.filter((item) =>
      keys.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setWorkingHoursDetails(filteredResults);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch]);

  return (
    <React.Fragment>
      <Mui.Grid container>
        <Mui.Grid xs={9.6}></Mui.Grid>
        <Mui.Grid xs={2} sx={{ paddingTop: 3 }}>
          <DateRangePicker
            handleStartDate={handleStartDate}
            selectedDateRange={selectedDateRange}
          />
        </Mui.Grid>
      </Mui.Grid>
      <>
        <Mui.Grid
          conatiner
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: 3,
          }}
        >
          <Mui.Box
            sx={{
              boxShadow: 3,
              marginRight: 2,
              padding: 4.5,
              borderRadius: 3,
              width: "17%",
              display: "flex",
              alignSelf: "center",
              justifyContent: "space-around",
              backgroundColor: themes.lightBlue,
            }}
          >
            <Mui.Grid item xs={0.5} sx={{ paddingTop: 0.5 }}>
              <Mui.Avatar
                sx={{
                  width: 60,
                  height: 60,
                  bgcolor: "#90c6f0",
                }}
                alt={userDetails?.username?.charAt(0).toUpperCase()}
                src={userDetails?.image_url}
              />
            </Mui.Grid>

            <Mui.Grid item xs={1} sx={{ paddingTop: 0.5 }}>
              <Mui.Typography sx={{ fontWeight: 800, fontSize: 23 }}>
                {userDetails?.username?.length > 10 ? (
                  <Mui.Tooltip title={userDetails?.username}>
                    <span>{`${userDetails?.username.substring(0, 10)}..`}</span>
                  </Mui.Tooltip>
                ) : (
                  userDetails?.username
                )}
              </Mui.Typography>
              <Mui.Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
                {userDetails?.role}
              </Mui.Typography>
            </Mui.Grid>
          </Mui.Box>

          <Mui.Box
            sx={{
              boxShadow: 3,
              padding: 3,
              borderRadius: 3,
              backgroundColor: themes.grayColor,
              width: "83%",
            }}
          >
            <Mui.Grid container spacing={1}>
              <Mui.Grid
                item
                xs={1.2}
                sx={{ borderRight: "1.5px solid white", textAlign: "center" }}
              >
                <Mui.Typography>
                  <Mui.Typography
                    sx={{
                      color: themes.whiteColor,
                      fontSize: 20,
                      fontWeight: 800,
                    }}
                  >
                    {userWeeklyReport.working_days
                      ? userWeeklyReport.working_days
                      : 0}
                  </Mui.Typography>
                  <Mui.Typography
                    sx={{
                      color: themes.whiteColor,
                      fontSize: 15,
                      fontWeight: 500,
                    }}
                  >
                    Days
                  </Mui.Typography>
                  <Mui.Typography
                    sx={{
                      color: themes.whiteColor,
                      fontSize: 19,
                      fontWeight: 600,
                    }}
                  >
                    Worked
                  </Mui.Typography>
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.4}
                sx={{ borderRight: "1.5px solid white", textAlign: "center" }}
              >
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 20,
                    fontWeight: 800,
                  }}
                >
                  {userWeeklyReport.total_late_days
                    ? userWeeklyReport.total_late_days
                    : 0}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  Days
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  Late
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.4}
                sx={{ borderRight: "1.5px solid white", textAlign: "center" }}
              >
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 20,
                    fontWeight: 800,
                  }}
                >
                  {userWeeklyReport.personal_leave
                    ? userWeeklyReport.personal_leave
                    : 0}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  Days
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  Leave
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                item
                xs={1.4}
                sx={{ borderRight: "1.5px solid white", textAlign: "center" }}
              >
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 20,
                    fontWeight: 800,
                  }}
                >
                  {userWeeklyReport.total_break_hours
                    ? userWeeklyReport.total_break_hours
                    : 0}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  Hrs
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  Break
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.4}
                sx={{ borderRight: "1.5px solid white", textAlign: "center" }}
              >
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 20,
                    fontWeight: 800,
                  }}
                >
                  {userWeeklyReport.absent_days
                    ? userWeeklyReport.absent_days
                    : 0}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  Days
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  Absent
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.4}
                sx={{ borderRight: "1.5px solid white", textAlign: "center" }}
              >
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 20,
                    fontWeight: 800,
                  }}
                >
                  {userWeeklyReport.total_over_time
                    ? userWeeklyReport.total_over_time
                    : 0}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  Hrs
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  Over Time
                </Mui.Typography>
              </Mui.Grid>

              <Mui.Grid
                item
                xs={1.4}
                sx={{ borderRight: "1.5px solid white", textAlign: "center" }}
              >
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 20,
                    fontWeight: 800,
                  }}
                >
                  {userWeeklyReport.total_permission_days
                    ? userWeeklyReport.total_permission_days
                    : 0}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  Times
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  Permission
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid
                item
                xs={1.4}
                sx={{ borderRight: "1.5px solid white", textAlign: "center" }}
              >
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 20,
                    fontWeight: 800,
                  }}
                >
                  {userWeeklyReport.weekly_working_hours
                    ? userWeeklyReport.weekly_working_hours
                    : 0}
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 15,
                    fontWeight: 500,
                  }}
                >
                  Hrs
                </Mui.Typography>
                <Mui.Typography
                  sx={{
                    color: themes.whiteColor,
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  Worked
                </Mui.Typography>
              </Mui.Grid>
              <Mui.Grid item xs={1} sx={{ marginTop: 2 }}>
                &nbsp; &nbsp; &nbsp;
                <Mui.IconButton
                  onClick={() => DownloadData()}
                  sx={{
                    backgroundColor: themes.primary,
                    "&:hover": {
                      backgroundColor: themes.primary,
                    },
                  }}
                >
                  {" "}
                  <DownloadForOffline
                    fontSize="large"
                    sx={{
                      color: themes.blueColor,
                    }}
                  />
                </Mui.IconButton>
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Box>
        </Mui.Grid>

        <Mui.TableContainer
          sx={{
            marginTop: 2,
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "97.5%",
          }}
          className="w-96 border  rounded "
        >
          {" "}
          {handleLoading ? (
            <Loader info="Loading..." />
          ) : (
            <Mui.Table className="border">
              <Mui.TableHead
                sx={{
                  backgroundColor: themes.primary,
                  position: "sticky",
                  top: -1,
                  zIndex: 10,
                }}
              >
                <Mui.TableRow>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}></Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>Date</Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>Shift</Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Check-In
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Check-Out
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>Break</Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Over Time
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Working Hours
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>
                      Leave Credit Hours
                    </Mui.Typography>
                  </Mui.TableCell>
                  <Mui.TableCell>
                    <Mui.Typography fontWeight={"bold"}>Status</Mui.Typography>
                  </Mui.TableCell>
                </Mui.TableRow>
              </Mui.TableHead>
              <Mui.TableBody>
                {workingHoursDetails?.length === 0 ? (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={10}>
                      <Mui.Alert severity="info">No data available</Mui.Alert>
                    </Mui.TableCell>
                  </Mui.TableRow>
                ) : (
                  workingHoursDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((user) => (
                      <>
                        <Mui.TableRow>
                          <Mui.TableCell>
                            {expandRow === user.date ? (
                              <Mui.IconButton
                                onClick={() => handleTableClick(null)}
                              >
                                <KeyboardArrowDown />
                              </Mui.IconButton>
                            ) : user.status === "A" || user.status === "L" ? (
                              ""
                            ) : (
                              <Mui.IconButton
                                onClick={() => handleTableClick(user.date)}
                              >
                                <KeyboardArrowRight />
                              </Mui.IconButton>
                            )}
                          </Mui.TableCell>
                          <Mui.TableCell>{user.date}</Mui.TableCell>
                          <Mui.TableCell>{user.shift}</Mui.TableCell>
                          <Mui.TableCell>
                            {user.check_in_time_formatted}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {user.check_out_time_formatted}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {user.status === "A" ||
                            user.status === "L" ||
                            user.status === "W" ? null : (
                              <Mui.Typography>
                                {user.break_hours === "" ||
                                user.break_hours === "00:00"
                                  ? "00:00"
                                  : user.break_hours}
                              </Mui.Typography>
                            )}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {user.status === "A" ||
                            user.status === "L" ||
                            user.status === "W" ? null : (
                              <Mui.Typography>{user.over_time}</Mui.Typography>
                            )}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {user.status === "A" ||
                            user.status === "L" ||
                            user.status === "W" ? null : (
                              <Mui.Typography>
                                {user.working_hours === "" ||
                                user.working_hours === "00:00"
                                  ? "00:00"
                                  : user.working_hours}
                              </Mui.Typography>
                            )}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            {user.status === "L" || user.status === "H" ? (
                              <Mui.Typography>
                                {user.Leave_credit_hours}
                              </Mui.Typography>
                            ) : user.status === "HL" ||
                              user.status === "PL" ||
                              user.status === "LP" ? (
                              <Mui.Typography>
                                {user.Leave_credit_hours}
                              </Mui.Typography>
                            ) : null}
                          </Mui.TableCell>
                          <Mui.TableCell>
                            <Mui.Typography
                              sx={{
                                display: "inline-block",
                                width: "28px",
                                color:
                                  user.status === "W"
                                    ? themes.blackColor
                                    : themes.whiteColor,
                                border:
                                  user.status === "W"
                                    ? "1px solid black"
                                    : "none",
                                height: "28px",
                                textAlign: "center",
                                lineHeight: "29px",
                                backgroundColor: getColorWorkingHours(
                                  user.status
                                ),
                              }}
                            >
                              {user.status === "HL" ||
                              user.status === "PL" ||
                              user.status === "LP" ? (
                                <Mui.Grid container>
                                  {user.status === "HL" && (
                                    <>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor:
                                            user.half_day_leave_period === "2nd"
                                              ? themes.greenColor
                                              : themes.yellowColor,
                                        }}
                                      >
                                        H
                                      </Mui.Typography>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor:
                                            user.half_day_leave_period === "2nd"
                                              ? themes.yellowColor
                                              : themes.greenColor,
                                        }}
                                      >
                                        L
                                      </Mui.Typography>
                                    </>
                                  )}
                                  {user.status === "PL" && (
                                    <>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor: themes.greenColor,
                                        }}
                                      >
                                        P
                                      </Mui.Typography>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor: themes.yellowColor,
                                        }}
                                      >
                                        L
                                      </Mui.Typography>
                                    </>
                                  )}
                                  {user.status === "LP" && (
                                    <>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor: themes.yellowColor,
                                        }}
                                      >
                                        L
                                      </Mui.Typography>
                                      <Mui.Typography
                                        sx={{
                                          width: "14px",
                                          height: "28px",
                                          textAlign: "center",
                                          lineHeight: "29px",
                                          backgroundColor: themes.greenColor,
                                        }}
                                      >
                                        P
                                      </Mui.Typography>
                                    </>
                                  )}
                                </Mui.Grid>
                              ) : (
                                user.status
                              )}
                            </Mui.Typography>
                          </Mui.TableCell>
                        </Mui.TableRow>
                        {expandRow === user.date && (
                          <Mui.TableRow>
                            <Mui.TableCell colSpan={10}>
                              <Mui.TableContainer
                                sx={{
                                  width: "100%",
                                  marginTop: 0,
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                className="w-96 border rounded"
                              >
                                {user.break_details.length !== 0 ? (
                                  <Mui.Table className="border">
                                    <Mui.TableHead
                                      sx={{
                                        backgroundColor: themes.primary,
                                      }}
                                    >
                                      <Mui.TableRow>
                                        <Mui.TableCell></Mui.TableCell>
                                        <Mui.TableCell></Mui.TableCell>
                                        <Mui.TableCell>
                                          <Mui.Typography fontWeight={"bold"}>
                                            Break Start
                                          </Mui.Typography>
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          <Mui.Typography fontWeight={"bold"}>
                                            Break End
                                          </Mui.Typography>
                                        </Mui.TableCell>
                                        <Mui.TableCell>
                                          <Mui.Typography fontWeight={"bold"}>
                                            Overall Break Time
                                          </Mui.Typography>
                                        </Mui.TableCell>
                                      </Mui.TableRow>
                                    </Mui.TableHead>
                                    <Mui.TableBody>
                                      {user.break_details.map(
                                        (breakDetail, index) => (
                                          <Mui.TableRow key={index}>
                                            <Mui.TableCell></Mui.TableCell>
                                            <Mui.TableCell></Mui.TableCell>
                                            <Mui.TableCell>
                                              {breakDetail.break_out &&
                                              breakDetail.break_out !== "null"
                                                ? new Date(
                                                    `2000-01-01T${breakDetail.break_out}`
                                                  ).toLocaleString([], {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: true,
                                                  })
                                                : "00:00"}
                                            </Mui.TableCell>
                                            <Mui.TableCell>
                                              {breakDetail.break_in &&
                                              breakDetail.break_in !== "null"
                                                ? new Date(
                                                    `2000-01-01T${breakDetail.break_in}`
                                                  ).toLocaleString([], {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                    hour12: true,
                                                  })
                                                : "00:00"}
                                            </Mui.TableCell>
                                            <Mui.TableCell>
                                              {
                                                breakDetail.individual_break_time
                                              }
                                            </Mui.TableCell>
                                          </Mui.TableRow>
                                        )
                                      )}
                                    </Mui.TableBody>
                                  </Mui.Table>
                                ) : (
                                  <Mui.Stack sx={{ padding: 0.5 }} fullWidth>
                                    <Mui.Alert severity="info">
                                      There is a no available breaks
                                    </Mui.Alert>
                                  </Mui.Stack>
                                )}
                              </Mui.TableContainer>
                            </Mui.TableCell>
                          </Mui.TableRow>
                        )}
                      </>
                    ))
                )}
              </Mui.TableBody>
              {workingHoursDetails?.length === 0 ? null : (
                <Mui.TableBody>
                  <Mui.TableRow>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell>
                      <Mui.Typography>Total</Mui.Typography>
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {" "}
                      {userWeeklyReport.total_over_time}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {userWeeklyReport.total_working_hours}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {" "}
                      {userWeeklyReport.total_leave_credit_hours}
                    </Mui.TableCell>
                    <Mui.TableCell></Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableBody>
              )}
            </Mui.Table>
          )}
        </Mui.TableContainer>
        <br></br>
        {workingHoursDetails?.length > 10 && (
          <Mui.TablePagination
            sx={{ marginRight: 5 }}
            className="custom-pagination"
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={workingHoursDetails?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </>
      <GlobalDialogBox
        handleCloseDialog={handleCloseDialog}
        open={breakPopup}
        title="Add Breaks"
      >
        <Mui.Grid container sx={{ paddingBottom: 1 }}>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Date</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingLeft: 2 }}>
            <Mui.Typography>:</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>
              {breakDayDetails ? breakDayDetails?.date : null}
            </Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Break Start Time</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={1}></Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <Mui.Typography>Break End Time</Mui.Typography>
          </Mui.Grid>
        </Mui.Grid>
        <Mui.Grid container>
          <Mui.Grid item xs={5.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Break Start"
                  onChange={(newValue) => {
                    setBreakOutTime(moment(newValue["$d"]).format("hh:mm A"));
                    validate();
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {breakOutError === "Please enter breakout time" ? (
              <Mui.Typography sx={{ color: themes.redColor, fontSize: 12 }}>
                {breakOutError}
              </Mui.Typography>
            ) : null}
          </Mui.Grid>
          <Mui.Grid item xs={1} sx={{ paddingTop: 3, paddingLeft: 2 }}>
            <Mui.Typography> To</Mui.Typography>
          </Mui.Grid>
          <Mui.Grid item xs={5.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  label="Break End"
                  onChange={(newValue) => {
                    setBreakInTime(moment(newValue["$d"]).format("hh:mm A"));
                    validate();
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            {breakInError === "Please enter breakin time" ? (
              <Mui.Typography sx={{ color: "red" }}>
                {breakInError}
              </Mui.Typography>
            ) : null}
          </Mui.Grid>
          <Mui.Typography sx={{ color: themes.redColor, fontSize: 12 }}>
            {ediBreakErrors === "Breakout time is lesser then breakin time"
              ? ediBreakErrors
              : null}{" "}
          </Mui.Typography>
        </Mui.Grid>
        <Mui.Grid container sx={{ paddingTop: 2 }}>
          <Mui.Grid item xs={10}></Mui.Grid>
          <Mui.Grid item xs={2}>
            <Mui.Button
              sx={{
                backgroundColor: themes.button,
                color: themes.whiteColor,
                "&:hover": {
                  backgroundColor: themes.button,
                },
              }}
              onClick={() => createNewBreaks()}
            >
              Create
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
      </GlobalDialogBox>
    </React.Fragment>
  );
};
