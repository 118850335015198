import React, { useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { AssignGroupUnitHeader } from "pages/Administration/AdministrationHeaders";
import { useSelector } from "react-redux";
import { useFetch } from "services/hooks/useFetch";
import { Edit, Delete } from "@mui/icons-material";
export const AssignUnitTable = ({
  setRenderComponentName,
  setViewAssignDetails,
}) => {
  const { domain, globalSearchValue } = useSelector(
    (state) => state.tokenReducer
  );
  const [updateData, setUpdateData] = useState(true);
  const [assignSummaryDetails, setAssignSummaryDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const {
    result: assignmentSummary,
    setLoad: reCallApi,
    loading,
  } = useFetch(`${domain}assignment_summary/`);

  useEffect(() => {
    if (updateData && assignmentSummary?.length !== 0 && assignmentSummary) {
      setAssignSummaryDetails(assignmentSummary);
      setUpdateData(false);
    }
  }, [assignmentSummary, updateData]);

  //   useEffect(() => {
  //     if (assignmentSummary?.length !== 0 || assignmentSummary?.length === 0) {
  //       const filteredResults = assignmentSummary?.filter((item) =>
  //         keys.some((key) =>
  //           item[key]
  //             ?.toString()
  //             .toLowerCase()
  //             .includes(globalSearchValue.toString().toLowerCase())
  //         )
  //       );
  //       setIncentiveStateData(filteredResults);
  //       setPage(0);
  //     }
  //   }, [globalSearchValue, assignmentSummary]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <React.Fragment>
      <React.Fragment>
        <Mui.TableContainer
          sx={{ mt: 2, width: "100%", paddingLeft: 1, maxHeight: 850 }}
        >
          <Mui.Table>
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
                position: "sticky",
                top: -1,
                zIndex: 10,
              }}
            >
              <Mui.TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    height: "10px",
                    padding: "6px 30px",
                  },
                }}
              >
                {AssignGroupUnitHeader.map((head) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {head.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {assignSummaryDetails
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((account, index) => (
                  <React.Fragment>
                    <Mui.TableRow
                      sx={{
                        "& .MuiTableCell-root": {
                          height: "10px",
                          padding: "6px 35px",
                        },
                        cursor: "pointer",
                      }}
                    >
                      {AssignGroupUnitHeader.map((head) =>
                        head.name === "Actions" ? (
                          <Mui.TableCell>
                            <Mui.Stack direction="row">
                              <Mui.IconButton
                                onClick={() => {
                                  setRenderComponentName("ViewAssignGroup");
                                  //   setDeleteIncentiveDetails(account);
                                  //   setRenderComponentName("EditAssignGroup");
                                  //   dispatch(setGlobalSearchValue(""));
                                }}
                              >
                                <Edit sx={{ fontSize: "10" }} />
                              </Mui.IconButton>
                              <Mui.IconButton
                                onClick={() => {
                                  //   setDeleteIncentiveDetails(account);
                                  //   setOpenDialog(true);
                                }}
                              >
                                <Delete />
                              </Mui.IconButton>
                            </Mui.Stack>
                          </Mui.TableCell>
                        ) : (
                          <Mui.TableCell
                            onClick={() => {
                              setViewAssignDetails(account);
                              setRenderComponentName("ViewAssignGroup");
                              //   history.push("/admin/work-unit/members");
                            }}
                          >
                            {account[head.key] ? account[head.key] : "N/A"}
                          </Mui.TableCell>
                        )
                      )}
                    </Mui.TableRow>
                  </React.Fragment>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
        <Mui.Grid
          container
          sx={{
            paddingTop: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: 1,
          }}
        >
          {assignSummaryDetails?.length > 10 && (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={assignSummaryDetails?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Mui.Grid>
      </React.Fragment>
    </React.Fragment>
  );
};
