import React from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BasicInfo } from "./BasicInfo";
export const CreateEmployee = () => {
  return (
    <React.Fragment>
      {/* <Mui.Stack></Mui.Stack> */}
      <Mui.Grid container sx={{ paddingTop: 3, paddingRight: 3 }}>
        {" "}
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          padding: 1,
          width: "99.5%",
          height: "100%",
          borderRadius: 5,
          border: `5px solid ${themes.sidebarDivider}`,
        }}
        component={Mui.Paper}
      >
        <Mui.Grid container></Mui.Grid>
        <BasicInfo />
      </Mui.Grid>
    </React.Fragment>
  );
};
