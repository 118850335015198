import React, { useEffect, useState, useCallback } from "react";
import { TopBar } from "./TopBar";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { BasicInfo } from "./BasicInfo";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
// import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { PersonalInfo } from "./PersonalInfo";
import { SalaryInfo } from "./SalaryInfo";
export const EditEmployee = () => {
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { pageno, userid } = useSelector((state) => state.hrportal);
  const [getEmployeeDetails, seyGetEmployeeDetails] = useState(true);
  const [userId, setUserId] = useState(userid);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [updateUserDetails, setUpdateUserDetails] = useState(true);

  const getSelectedUserDetails = useCallback(
    async (id) => {
      try {
        const response = await fetch(
          `${domain}employee/details?user_id=${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `token ${token}`,
            },
          }
        );
        const res = await response.json();
        if (response.ok) {
          setEmployeeDetails(res);
          setUpdateUserDetails(true);
        } else if (response.status === 409) {
          sessionHandling();
        } else {
          throw new Error(res.error);
        }
      } catch (error) {
        showToast("error", error.message);
      }
    },
    [domain, token]
  );

  useEffect(() => {
    if (getEmployeeDetails) {
      getSelectedUserDetails(userId);
      // localStorage.setItem("editEmployeeIdNumber", null);
      // localStorage.setItem("editEmployeeDetails", false);
      seyGetEmployeeDetails(false);
    }
  }, [getEmployeeDetails, getSelectedUserDetails, userId]);

  const renderComponent = () => {
    switch (pageno) {
      case 1:
        return (
          <BasicInfo
            pageno={pageno}
            employeeDetails={employeeDetails}
            getSelectedUserDetails={getSelectedUserDetails}
            setUpdateUserDetails={setUpdateUserDetails}
            updateUserDetails={updateUserDetails}
            setUserId={setUserId}
          />
        );
      case 2:
        return (
          <PersonalInfo
            pageno={pageno}
            employeeDetails={employeeDetails}
            getSelectedUserDetails={getSelectedUserDetails}
            setUpdateUserDetails={setUpdateUserDetails}
            updateUserDetails={updateUserDetails}
            setUserId={setUserId}
          />
        );
      case 3:
        return (
          <SalaryInfo
            pageno={pageno}
            employeeDetails={employeeDetails}
            getSelectedUserDetails={getSelectedUserDetails}
            setUpdateUserDetails={setUpdateUserDetails}
            updateUserDetails={updateUserDetails}
            setUserId={setUserId}
          />
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Mui.Grid container sx={{ paddingTop: 2 }}></Mui.Grid>
      <Mui.Grid
        container
        sx={{
          padding: 2,
          width: "99.5%",
          height: "100%",
          borderRadius: 5,
          border: `5px solid ${themes.sidebarDivider}`,
        }}
      >
        <Mui.Grid container>
          <TopBar
            pageno={pageno}
            employeeDetails={employeeDetails}
            setUpdateUserDetails={setUpdateUserDetails}
          />
        </Mui.Grid>
        <Mui.Grid container>{renderComponent()}</Mui.Grid>
      </Mui.Grid>
    </React.Fragment>
  );
};
