import React, { useEffect, useState, useCallback } from "react";
import * as Mui from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";
import { themes } from "services/constants";
// import { ShiftGroupContext } from "../ShiftManageContext/ShiftGroupContext";
import { BackArrow } from "components/CustomComponents/BackArrow";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { getContrastingTextColor } from "services/utils/colorChecker";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";

function not(a, b, key = "user_id") {
  return Array.isArray(a) && Array.isArray(b)
    ? a.filter((value) => !b.some((bValue) => bValue[key] === value[key]))
    : [];
}

function intersection(a, b, key = "user_id") {
  return Array.isArray(a) && Array.isArray(b)
    ? a.filter((value) => b.some((bValue) => bValue[key] === value[key]))
    : [];
}

function union(a, b) {
  return [...(Array.isArray(a) ? a : []), ...not(b, a)];
}

export const AddEmployee = ({
  viewAssignDetails,
  updateShowAddorMoveEmployee,
  setAddEmployee,
  viewDepName,
  viewSftGrpDet,
  setReloadMemberApi,
}) => {
  //   const { domain, token } = useContext(ShiftGroupContext);
  const { domain, token } = useSelector((state) => state.tokenReducer);
  const { result: department } = useFetch(`${domain}department/`);
  const { result: workUnitGroup } = useFetch(`${domain}work_unit_groups/`);
  const [grpDepMemChecked, setGrpDepMemChecked] = useState([]);
  const [grpDepartments, setGrpDepartments] = useState([]);
  const [selGrpDept, setSelGrpDept] = useState("");
  const [selGrpDeptId, setSelGrpDeptId] = useState("");
  const [departmentSelections, setDepartmentSelections] = useState({});
  const [departmentName, setDepartmentName] = useState("");
  const [groupNameLeft, setGroupNameLeft] = useState("");
  const [groupNameRight, setGroupNameRight] = useState("");

  const left = departmentSelections[selGrpDept]?.left || [];
  const right = departmentSelections[selGrpDept]?.right || [];

  const leftChecked = intersection(grpDepMemChecked, left);
  const rightChecked = intersection(grpDepMemChecked, right);
  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("department_name", viewAssignDetails?.department_name);
    setValue("name", viewAssignDetails?.name);
    setValue("effective_from", viewAssignDetails?.start_date);
    // ?.split("-").reverse().join("-")
    setValue("effective_to", viewAssignDetails?.end_date);
    setValue("department", viewAssignDetails.department);
    setDepartmentName(viewAssignDetails?.department_name);
  }, [
    setValue,
    viewAssignDetails.department,
    viewAssignDetails?.department_name,
    viewAssignDetails?.end_date,
    viewAssignDetails?.name,
    viewAssignDetails?.start_date,
  ]);

  useEffect(() => {
    if (
      viewSftGrpDet?.departments &&
      Array.isArray(viewSftGrpDet.departments)
    ) {
      const depArr = [];
      if (viewDepName) {
        viewSftGrpDet.departments
          .filter((dep) => dep.department === viewDepName)
          .forEach((dep) => depArr.push(dep.department));
      } else {
        viewSftGrpDet.departments.forEach((dep) => depArr.push(dep.department));
      }
      if (depArr?.length > 0) {
        setGrpDepartments(depArr);
        setSelGrpDept(depArr[0]);
        let depFil = viewSftGrpDet?.departments?.filter(
          (dep) => dep.department === depArr[0]
        );
        setSelGrpDeptId(depFil[0]?.id || "");
      }
    }
  }, [viewSftGrpDet, viewDepName]);

  const getAllSelectedUserIds = () => {
    const allRightUserIds = [];
    Object.keys(departmentSelections).forEach((dept) => {
      const rightSideUsers = departmentSelections[dept]?.right || [];
      rightSideUsers.forEach((user) => allRightUserIds.push(user?.user_id));
    });

    return allRightUserIds;
  };

  const selectedUserIds = getAllSelectedUserIds();

  const fetchDepEmployeeData = useCallback(
    async (dept, deptId) => {
      if (dept) {
        try {
          const response = await fetch(
            `${domain}unassign-shift-group-user/?shift_group_id=${viewSftGrpDet?.id}&department_id=${deptId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `token ${token}`,
              },
            }
          );
          const res = await response.json();
          if (response.ok) {
            setDepartmentSelections((prev) => ({
              ...prev,
              [dept]: { left: res || [], right: [] },
            }));
          } else if (response.status === 409) {
            sessionHandling();
          } else {
            throw new Error(res.error);
          }
        } catch (error) {
          showToast("error", error.message);
        }
      }
    },
    [domain, token, viewSftGrpDet?.id]
  );

  useEffect(() => {
    if (!departmentSelections[selGrpDept]) {
      fetchDepEmployeeData(selGrpDept, selGrpDeptId);
    }
  }, [selGrpDept, selGrpDeptId, fetchDepEmployeeData, departmentSelections]);

  const handleToggle = (value) => () => {
    const currentIndex = grpDepMemChecked.findIndex(
      (item) => item.user_id === value.user_id
    );
    const newChecked = [...grpDepMemChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setGrpDepMemChecked(newChecked);
  };

  const numberOfChecked = (items) =>
    intersection(grpDepMemChecked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setGrpDepMemChecked(not(grpDepMemChecked, items));
    } else {
      setGrpDepMemChecked(union(grpDepMemChecked, items));
    }
  };

  const handleCheckedRight = () => {
    const newLeft = not(left, leftChecked);
    const newRight = right.concat(leftChecked);

    setDepartmentSelections((prev) => ({
      ...prev,
      [selGrpDept]: { left: newLeft, right: newRight },
    }));
    setGrpDepMemChecked(not(grpDepMemChecked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const newRight = not(right, rightChecked);
    const newLeft = left.concat(rightChecked);

    setDepartmentSelections((prev) => ({
      ...prev,
      [selGrpDept]: { left: newLeft, right: newRight },
    }));
    setGrpDepMemChecked(not(grpDepMemChecked, rightChecked));
  };

  const handleAddEmployee = async () => {
    try {
      const response = await fetch(`${domain}add-employee-shift-group/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          shift_group_id: viewSftGrpDet?.id,
          user_ids: selectedUserIds,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        updateShowAddorMoveEmployee(false);
        showToast("success", res.message);
        setReloadMemberApi(true);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    }
  };

  const handleDepartment = (event, data) => {
    if (data) {
      setValue("departmentName", data?.label);
      setValue("department_id", data?.id);
      setDepartmentName(data?.label);
    } else {
      setValue("departmentName", "");
      setValue("department_id", null);
      setDepartmentName("");
    }
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
  };

  const customSelectedEmployeeList = (title, items) => (
    <Mui.Card>
      <Mui.CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Mui.Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length}`}
      />
      <Mui.Divider />
      <Mui.List
        sx={{
          width: "100%",
          height: {
            xs: 200,
            sm: 300,
            md: 300,
            lg: 430,
          },
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.user_id}-label`;

          return (
            <Mui.ListItemButton
              key={value.user_id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <Mui.ListItemIcon>
                <Mui.Checkbox
                  checked={grpDepMemChecked.some(
                    (item) => item.user_id === value.user_id
                  )}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </Mui.ListItemIcon>
              <Mui.ListItemAvatar>
                <Mui.Avatar
                  alt={value?.user_name?.charAt(0)}
                  src={value?.image_url}
                />
              </Mui.ListItemAvatar>
              <Mui.ListItemText id={labelId} primary={`${value.user_name}`} />
            </Mui.ListItemButton>
          );
        })}
      </Mui.List>
    </Mui.Card>
  );

  const handlAssignedFrom = (event, data) => {
    if (data) {
      setValue("work_unit_left", data?.label);
      setValue("id", data?.id);
      setGroupNameLeft(data?.label);
    } else {
      setValue("work_unit_left", "");
      setValue("id", null);
      setGroupNameLeft("");
    }
  };

  const handlAssignedTo = (event, data) => {
    if (data) {
      setValue("work_unit_right", data?.label);
      setValue("id", data?.id);
      setGroupNameRight(data?.label);
    } else {
      setValue("work_unit_right", "");
      setValue("id", null);
      setGroupNameRight("");
    }
  };

  return (
    <>
      <Mui.Grid container sx={{ paddingTop: 2 }}>
        <Mui.Grid>
          <BackArrow
            actionFunction={() => {
              setAddEmployee(false);
            }}
          />
        </Mui.Grid>
        <Mui.Grid>
          <Mui.Typography sx={{ fontSize: 24, fontWeight: 700, paddingTop: 1 }}>
            Group 1 / Add Employee
          </Mui.Typography>
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid container>
        <Mui.Grid xs={1.5}></Mui.Grid>
        <Mui.Grid xs={4.1} sx={{ paddingTop: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 18,
              color: themes.headLine,
              paddingBottom: 0.5,
              fontWeight: "bold",
            }}
          >
            Department
          </Mui.Typography>
          <CustomAutoComplete
            dataList={department.map((obj) => ({
              ...obj,
              label: obj.department,
            }))}
            selectedValue={departmentName}
            rules={{
              ...register("departmentName", {
                required: "Department name is required",
              }),
            }}
            updateDataFunction={handleDepartment}
            placeHolder="Please select the department name"
            errorMessage={
              errors?.departmentName?.message
                ? errors?.departmentName?.message
                : ""
            }
            textBoxError={errors?.departmentName ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid xs={0.7}></Mui.Grid>
        <Mui.Grid xs={2} sx={{ paddingTop: 1 }}>
          {" "}
          <Mui.Typography
            sx={{
              fontSize: 18,
              color: themes.headLine,
              paddingBottom: 0.5,
              fontWeight: "bold",
            }}
          >
            Effective From
          </Mui.Typography>
          <Mui.TextField
            id="from-date"
            type="date"
            size="small"
            {...register("effective_from", {
              required: "Effective from is required.",
              validate: validateStartDate,
            })}
            error={Boolean(errors.effective_from)}
            helperText={
              errors.effective_from?.message && errors.effective_from.message
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            disableOpenPicker
          />
        </Mui.Grid>
        <Mui.Grid xs={0.2}></Mui.Grid>
        <Mui.Grid xs={2} sx={{ paddingTop: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 18,
              color: themes.headLine,
              paddingBottom: 0.5,
              fontWeight: "bold",
            }}
          >
            Effective To
          </Mui.Typography>
          <Mui.TextField
            id="to-date"
            type="date"
            size="small"
            label="To"
            {...register("effective_to", {
              required: "Effective to is required.",
              validate: validateStartDate,
            })}
            error={Boolean(errors.effective_to)}
            helperText={
              errors.effective_to?.message && errors.effective_to.message
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            disableOpenPicker
          />
        </Mui.Grid>
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "Center",
          alignItems: "Center",
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        <Mui.Grid
          container
          component={Mui.Paper}
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "Center",
            alignItems: "Center",
            padding: 2,
            backgroundColor: "#f7f5f6",
          }}
        >
          <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
            <>
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "Center",
                  alignItems: "Center",
                }}
              >
                <Mui.Grid
                  component={Mui.Paper}
                  sx={{
                    backgroundColor: themes.darkPrimary,
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  <Mui.Typography
                    sx={{
                      padding: 1,
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    Assigned From
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>

              <Mui.FormControl fullWidth>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  WorkUnit Group
                </Mui.Typography>
                {/* <Mui.Select
                  value={selGrpDept}
                  sx={{ border: themes.primary }}
                  onChange={(e) => {
                    let depFil = viewSftGrpDet?.departments?.filter(
                      (dep) => dep.department === e.target.value
                    );
                    setSelGrpDeptId(depFil[0].id);
                    setSelGrpDept(e.target.value);
                  }}
                >
                  {grpDepartments.map((dept) => (
                    <Mui.MenuItem key={dept} value={dept}>
                      {dept}
                    </Mui.MenuItem>
                  ))}
                </Mui.Select> */}

                <CustomAutoComplete
                  dataList={workUnitGroup.map((obj) => ({
                    ...obj,
                    label: obj.name,
                  }))}
                  selectedValue={groupNameLeft}
                  updateDataFunction={handlAssignedFrom}
                  placeHolder="Please select workunit group"
                />
              </Mui.FormControl>
              {customSelectedEmployeeList("Select Employee", left)}
            </>
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={12} md={1.5} lg={1}>
            <Mui.Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Mui.IconButton
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                sx={{
                  textTransform: "capitalize",
                  color: themes.headLine,
                }}
                variant="contained"
                size="medium"
              >
                <ArrowCircleRight color={themes.headLine} fontSize="large" />
              </Mui.IconButton>
              <Mui.IconButton
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                sx={{
                  textTransform: "capitalize",
                  color: themes.headLine,
                }}
                variant="contained"
                size="medium"
              >
                <ArrowCircleLeft color={themes.headLine} fontSize="large" />
              </Mui.IconButton>
            </Mui.Grid>
          </Mui.Grid>

          <Mui.Grid item xs={12} sm={12} md={6} lg={5}>
            <>
              <Mui.Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "Center",
                  alignItems: "Center",
                }}
              >
                <Mui.Grid
                  component={Mui.Paper}
                  sx={{
                    backgroundColor: themes.darkPrimary,
                    paddingLeft: 5.5,
                    paddingRight: 5.5,
                  }}
                >
                  <Mui.Typography
                    sx={{
                      padding: 1,
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    Assigned To
                  </Mui.Typography>
                </Mui.Grid>
              </Mui.Grid>
              <Mui.FormControl fullWidth>
                <Mui.Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: "bold",
                  }}
                >
                  WorkUnit Group
                </Mui.Typography>
                {/* <Mui.Select disabled={true} value={selGrpDept}>
                  {grpDepartments.map((dept) => (
                    <Mui.MenuItem key={dept} value={dept}>
                      {dept}
                    </Mui.MenuItem>
                  ))}
                </Mui.Select> */}
                <CustomAutoComplete
                  dataList={workUnitGroup.map((obj) => ({
                    ...obj,
                    label: obj.name,
                  }))}
                  selectedValue={groupNameRight}
                  updateDataFunction={handlAssignedTo}
                  placeHolder="Please select workunit group"
                />
              </Mui.FormControl>
              {customSelectedEmployeeList("Selected Employees", right)}
            </>
          </Mui.Grid>
          <Mui.Grid container sx={{ mt: 2 }}>
            <Mui.Grid xs={8.5}></Mui.Grid>
            <Mui.Grid
              xs={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Mui.Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: themes.primaryIndicator,
                  "&:hover": {
                    backgroundColor: themes.primaryIndicator,
                  },
                  color: getContrastingTextColor(themes.primaryIndicator),
                }}
                size="large"
                disabled={selectedUserIds?.length === 0}
                onClick={handleAddEmployee}
              >
                Add
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
