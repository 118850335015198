export const emailValidationPatterns =
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const aadharRegex = /^\d{12}$/;
export const onlyNumberRegex = /^\d*$/;
export const numberWithDecimalRegex = /^\d*\.?\d*$/;
export const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const mustbe12Digits = /^\d{12}$/;
export const pfNumberRegex = /^[A-Z]{5}\d+$/;
export const specialCharacterRestrict = /^[a-zA-Z0-9 ]*$/; // allow space
export const specialCharacterRest = /^[a-zA-Z0-9]*$/;
export const rgxWithDot = /^[0-9]*\.?[0-9]*$/;
export const ifscCodePatterns = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const atleastOneCharacter = /^\d*[a-zA-Z][a-zA-Z0-9]*$/;
export const atleastOneCharacterWithSpace = /^(?=.*[a-zA-Z]).*$/;
export const atleastOneSpecialCharaters = /^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/;
// export const pfNumberRegex = /\b[A-Za-z0-9]{22}\b/g;
